import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { searchProduct } from "../../helpers/fetcher";
import { Returntext } from "../../helpers/helpers";

interface SearchProps {
  lang?: string;
  contentData?: any;
}

const Search: React.FC<SearchProps> = ({ lang, contentData }) => {
  const [searchValue, setSearchValue] = useState("");
  const [productData, setProductData] = useState([]);
  const navigate = useNavigate();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      navigate(`productsSearch/${searchValue}`);
      setSearchValue(""); // Clear the search value after redirection
    }
  };

  const handleProductClick = (productCode: string) => {
    navigate(`productsSearch/${productCode}`);
    setSearchValue(""); // Clear the search value after redirection
  };

  const fetchData = useCallback(async () => {
    if (searchValue.trim() === "") {
      setProductData([]);
      return;
    }
    try {
      const products = await searchProduct(1, 5, searchValue);
      setProductData(products?.items || []);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setProductData([]);
    }
  }, [searchValue]);

  useEffect(() => {
    fetchData();
  }, [searchValue, fetchData]);

  return (
    <div>
      <label className="input input-bordered flex items-center gap-2">
        <input
          type="text"
          className="grow w-[150px] nav_font_ka text-[16px]"
          placeholder={Returntext(contentData, "search_txt", lang)}
          value={searchValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 16 16"
          fill="currentColor"
          className="w-4 h-4 opacity-70"
        >
          <path
            fillRule="evenodd"
            d="M9.965 11.026a5 5 0 1 1 1.06-1.06l2.755 2.754a.75.75 0 1 1-1.06 1.06l-2.755-2.754ZM10.5 7a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Z"
            clipRule="evenodd"
          />
        </svg>
      </label>
      {searchValue && (
        <div className="absolute bg-white shadow-lg rounded-lg w-64 p-4">
          {productData.length > 0 ? (
            <>
              {productData.map((item: any, index) => (
                <div
                  key={index}
                  className="flex items-center justify-start gap-5 p-2 rounded hover:bg-gray-100 transition duration-200 cursor-pointer"
                  onClick={() => handleProductClick(item?.code)}
                >
                  <div className="flex items-center justify-center w-3 h-3 rounded-full bg-green-500"></div>
                  <div className="text-gray-800 font-medium text-left">
                    {item?.code?.split("-")[1]?.trim()}
                  </div>
                </div>
              ))}
              <div
                className="text-blue-500 hover:underline cursor-pointer mt-2 text-center"
                onClick={() => {
                  navigate(`productsSearch/${searchValue}`);
                  setSearchValue(""); // Clear the search value after redirection
                }}
              >
                {Returntext(contentData, "our_work_see_more", lang)}
              </div>
            </>
          ) : (
            <div className="text-gray-500">no_results</div>
          )}
        </div>
      )}
    </div>
  );
};

export default Search;
