import { Returntext } from "../../helpers/helpers";
import Button from "../button/Button";
import heroIMG from "../../assets/images/hero_main.png";

interface HeroType {
  contentData?: any;
  lang?: string;
}

const Hero: React.FC<HeroType> = ({ contentData, lang }) => {
  return (
    <div className="w-full h-[100vh] relative overflow-hidden">
      {/* Text Section */}
      <div className="absolute top-[30%] md:top-[40%] left-[5%] md:left-[10%] font-sans  md:text-left px-4 md:px-0">
        <div className="text-[24px] md:text-[28px] text-[#3bb085] h1_font_ka">
          {Returntext(contentData, "MG", lang)}
        </div>
        <div className="text-[36px] md:text-[60px] lg:text-[72px] leading-[40px] md:leading-[60px] lg:leading-[70px] font-bold h2_font_ka w-full md:w-[500px]">
          {Returntext(contentData, "MG_slogan", lang)}
        </div>
        <a href={`/ka/contact`}>
          <Button
            text={Returntext(contentData, "BTN_CONTACT_US", lang)}
            className="mt-4 md:mt-[20px] main_font_ka !uppercase"
          />
        </a>
      </div>

      {/* Background Image */}
      <div className="h-full relative overflow-hidden">
        <img
          alt="hero_img"
          src={heroIMG}
          className="opacity-20 w-full h-full object-cover"
        />
      </div>
    </div>
  );
};

export default Hero;
