import React from "react";
import { Returntext } from "../../helpers/helpers";
import aboutUS from "../../assets/icons/checkbox.svg";

interface AboutUSProps {
  contentData?: any;
  lang?: string;
}

const AboutUS: React.FC<AboutUSProps> = ({ contentData, lang }) => {
  const listItems: string[] = [
    Returntext(contentData, "about_body1", lang),
    Returntext(contentData, "about_body2", lang),
    Returntext(contentData, "about_body3", lang),
  ];

  return (
    <div
      id="aboutUS"
      className="relative w-full overflow-hidden bg-[#2B2D37] flex flex-col items-center justify-center py-12"
    >
      {/* Background with gradient */}
      <div className="absolute inset-0 w-full h-full bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px] bg-[#2B2D37] z-20 [mask-image:radial-gradient(transparent,white)] pointer-events-none" />

      <div className="flex flex-col lg:flex-row items-center lg:items-start justify-center lg:justify-between px-6 sm:px-[5dvw] lg:px-[10dvw] gap-8 lg:gap-[150px] my-[50px] lg:my-[100px] z-30">
        {/* About Us Heading and Title */}
        <div className="flex-1 flex flex-col gap-4 text-center lg:text-left">
          <div className="text-[24px] sm:text-[32px] text-[#fff] font-bold leading-[30px] sm:leading-[40px] z-30 h1_font_ka">
            {Returntext(contentData, "about_us", lang)}
          </div>
          <div className="text-[#fff] sm:text-[18px] w-full lg:w-[80%] z-30">
            {Returntext(contentData, "about_title", lang)}
          </div>

          {/* List of Items */}
          <ul className="w-full lg:w-[80%] flex flex-col gap-2 mt-3 z-30">
            {listItems.map((item, index) => (
              <li
                key={index}
                className="flex gap-2 text-[#fff] justify-start items-start pt-2"
              >
                <img
                  alt="checkbox icon"
                  src={aboutUS}
                  className="w-[14px] mt-1"
                />
                <span className="text-sm sm:text-base text-left">{item}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AboutUS;
