import { Returntext } from "../../helpers/helpers";
import fb from '../../assets/icons/facebook.svg';
import LN from '../../assets/icons/linkedin.svg';

interface FooterType {
  className?: string;
  contentData?: any;
  lang?: string;
}

const Footer: React.FC<FooterType> = ({ contentData, lang }) => {
  return (
    <div className="flex flex-col md:flex-row bg-[#2B2D37] justify-between items-start w-full py-10 px-6 md:px-[10vw] lg:px-[17vw] gap-10 md:gap-0">
      {/* Left section */}
      <div className="flex flex-col justify-between h-full gap-4">
        <div className="flex flex-col gap-5">
          <div className="text-[18px] md:text-[20px] text-[#3BB085] capitalize font-bold">
            {Returntext(contentData, "MG", lang)}
          </div>
          <div className="text-[#999999] capitalize text-[14px]">
            {Returntext(contentData, "MG_slogan", lang)}
          </div>
        </div>

        <div className="text-[#999999] capitalize flex gap-2">
          <a href={"https://www.facebook.com/montagegeorgia"} target="_blank">
            <img alt="FB" src={fb} className="cursor-pointer w-[20px] md:w-[25px]" />
          </a>
          <a
            href={"https://www.linkedin.com/company/montage-georgia-official/"}
            target="_blank"
          >
            <img alt="LinkedIn" src={LN} className="cursor-pointer w-[20px] md:w-[25px]" />
          </a>
        </div>
      </div>

      {/* Middle section */}
      <div className="flex flex-col gap-5">
        <div className="text-[#fff] text-[16px] capitalize font-bold">
          Montage Georgia
        </div>
        <div className="flex flex-col text-[#999999] capitalize gap-4 text-[14px]">
          <a href="/services">{Returntext(contentData, "services", lang)}</a>
          <a href="/projects">{Returntext(contentData, "projects", lang)}</a>
        </div>
      </div>

      {/* Right section */}
      <div className="flex flex-col gap-5">
        <div className="text-[#fff] text-[16px] capitalize font-bold">
          <a href="/contact">{Returntext(contentData, "contact_us", lang)}</a>
        </div>
        <div className="flex flex-col text-[#999999] gap-4 text-[14px]">
          <div>{Returntext(contentData, "email_value", lang)}</div>
          <div>{Returntext(contentData, "Office_value", lang)}</div>
          <div>{Returntext(contentData, "wh_value", lang)}</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
