import React, { useEffect, useState } from "react";
import { fetchPartners } from "../../helpers/fetcher";
import { PartnersCarousel } from "../carousel/PartnersCarousel";
import { Returntext } from "../../helpers/helpers";

interface PartnersProps {
  contentData?: any;
  lang?: string;
}

const Partners: React.FC<PartnersProps> = ({ contentData, lang }) => {
  const [partnersData, setPartnersData] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchPartners();
      setPartnersData(data);
    };

    fetchData();
  }, []);

  if (!partnersData) {
    return <div>Loading...</div>;
  }

  return (
    <div
      id="partners-section"
      className="py-20 bg-white bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]"
    >
      <div className="text-[#000] h2_font_ka text-[24px] sm:text-[28px] md:text-[32px] lg:text-[36px] xl:text-[42px] uppercase font-bold text-center">
        {Returntext(contentData, "Out_Partners", lang)}
      </div>
      <div className="w-full flex justify-center items-center h-auto min-h-[150px] relative">
        <PartnersCarousel data={partnersData}></PartnersCarousel>
      </div>
    </div>
  );
};

export default Partners;
