import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  fetchFilters,
  fetchNavbarDataById,
  fetchProducts,
  searchProduct,
} from "../helpers/fetcher";
import { TextGenerateEffect } from "../components/textGenerateEffect/TextGenerateEffect";
import {
  CardBody,
  CardContainer,
  CardItem,
} from "../components/animatedCard/AnimatedCard";
import { CircularPagination } from "../components/circularPagination/circularPagination";
import { Returntext, useContent, useLanguages } from "../helpers/contLang";
import notFoundIcon from "../assets/images/noItemFound.jpg";
import imgOther from "../assets/images/otherImage.png";

interface ParamsType {
  [key: string]: string | undefined;
  categoryId?: string;
  categoryName?: string;
}

interface SelectedFilters {
  [key: string]: string;
}

export default function Products() {
  const { categoryId, categoryName } = useParams<ParamsType>();
  const [currentPage, setCurrentPage] = useState(1);
  const [productData, setProductData] = useState<any>(null);
  const [navbarData, setNavbarData] = useState<any>(null);
  const [filterData, setFilterData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [showNotFound, setShowNotFound] = useState(false);
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    prm_1: "",
    prm_2: "",
    prm_3: "",
    prm_4: "",
    prm_5: "",
    prm_6: "",
    prm_7: "",
  });

  const fetchData = useCallback(
    async (page: number) => {
      try {
        setLoading(true);
        setShowNotFound(false);

        let products;
        if (categoryName === "searchProduct") {
          products = await searchProduct(page, 1, categoryId!);
        } else if (categoryName === "search") {
          products = await searchProduct(page, 12, categoryId!);
        } else {
          products = await fetchProducts(
            categoryId!,
            page,
            12,
            selectedFilters
          );
          const navbar = await fetchNavbarDataById(+categoryId!);
          const filters = await fetchFilters(categoryId!);
          setNavbarData(navbar);
          setFilterData(filters);
        }

        setProductData(products);

        if (products?.items?.length === 0) {
          setTimeout(() => {
            setShowNotFound(true);
          }, 1500);
        }

        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setLoading(false);
        setTimeout(() => {
          setShowNotFound(true);
        }, 1500);
      }
    },
    [categoryId, categoryName, selectedFilters]
  );

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, fetchData]);

  const handleFilterChange = (filterKey: string, value: string) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filterKey]: value,
    }));
  };

  return (
    <div className="min-h-screen bg-white bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]">
      {loading ? ( // Show loading spinner when fetching data
        <div className="pt-[200px] w-[95vw] m-auto">
          <div className="flex justify-center items-center h-1/2">
            <span className="loading loading-ring loading-xl w-[30%] bg-[#39B085]"></span>
          </div>
        </div>
      ) : (
        <div className="pt-[200px] w-[95vw] m-auto">
          <TextGenerateEffect
            words={lang === "ka" ? navbarData?.title_ka : navbarData?.title_en}
            className="mb-10 text-4xl ml-[7.5vw]"
          />
          <div className="flex flex-wrap lg:flex-nowrap w-full justify-between gap-[5vw]">
            <div className="flex flex-col gap-4 mb-8  ">
              {filterData && (
                <>
                  {Object.keys(selectedFilters).map(
                    (filterKey) =>
                      filterData[filterKey] && (
                        <div key={filterKey} className="w-full lg:w-[120px]">
                          <select
                            id={filterKey}
                            value={selectedFilters[filterKey] || ""}
                            onChange={(e) =>
                              handleFilterChange(filterKey, e.target.value)
                            }
                            className="block py-1 px-0 w-full text-sm text-gray-500 bg-transparent border-0 border-b-2 border-gray-200 appearance-none focus:outline-none focus:ring-0 focus:border-gray-200 peer"
                          >
                            <option value="">
                              {filterKey === "partners" ? "Partners" : "Filter"}
                            </option>
                            {filterData[filterKey]?.map(
                              (value: any, index: number) => (
                                <option
                                  key={index}
                                  value={
                                    typeof value === "object" ? value.id : value
                                  }
                                >
                                  {typeof value === "object"
                                    ? value.name
                                    : value}
                                </option>
                              )
                            )}
                          </select>
                        </div>
                      )
                  )}
                </>
              )}
            </div>
            <div className="w-full">
              {loading ? (
                <div className="flex justify-center items-center h-1/2">
                  <span className="loading loading-ring loading-xl w-[30%] bg-[#39B085]"></span>
                </div>
              ) : productData?.items?.length > 0 ? (
                <div className="flex flex-wrap justify-between items-center gap-3 w-full">
                  {/* The part you requested to not change */}
                  {productData.items.map((product: any, key: any) => (
                    <CardContainer className="inter-var" key={key}>
                      <CardBody className="bg-white relative max_width group/card hover:shadow-xl hover:shadow-emerald-500/[0.1] border-gray-200 h-auto rounded-xl p-4 border flex flex-col justify-between">
                        <div>
                          <CardItem
                            translateZ="50"
                            className="text-l font-bold text-neutral-600"
                          >
                            {lang === "ka"
                              ? product?.name_ka
                              : product?.name_en}
                          </CardItem>

                          <CardItem
                            as="p"
                            translateZ="60"
                            className="text-neutral-500 text-sm max-w-sm mt-2"
                          >
                            {lang === "ka"
                              ? product?.description_ka
                              : product?.description_en}
                          </CardItem>
                          <CardItem
                            as="p"
                            translateZ="60"
                            className="text-neutral-500 text-sm max-w-sm mt-2"
                          >
                            {product?.code?.split(/-(.*)/)[1]?.trim()}
                          </CardItem>
                          <CardItem
                            translateZ="100"
                            className="w-full mt-4 flex justify-center"
                          >
                            <img
                              src={`https://montage.ge/img/${product?.photo}`}
                              alt={product?.name_en}
                              className="w-auto h-[200px] object-cover"
                              onError={(e) => {
                                e.currentTarget.src = imgOther;
                              }}
                            />
                          </CardItem>
                        </div>
                        <CardItem
                          translateZ={20}
                          onClick={() => {
                            const url = `https://montage.ge/img/${product?.pdf}`;
                            window.open(url, "_blank", "noopener noreferrer");
                          }}
                          className="px-4 py-2 rounded-xl text-xs font-normal text-neutral-600 cursor-pointer"
                        >
                          {Returntext(
                            contentManagementData,
                            "data_sheet",
                            lang
                          )}
                        </CardItem>
                      </CardBody>
                    </CardContainer>
                  ))}
                </div>
              ) : (
                showNotFound && (
                  <div className="flex justify-center items-center mt-10">
                    <img
                      src={notFoundIcon}
                      alt="Not found"
                      className="w-full lg:w-[60%] object-cover"
                    />
                  </div>
                )
              )}
            </div>
          </div>
          <div className="flex justify-center items-center mt-4">
            {productData?.meta?.totalPages > 1 && (
              <CircularPagination
                activePage={currentPage}
                setActivePage={setCurrentPage}
                totalPages={productData?.meta?.totalPages}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
