import React from "react";
import { Carousel, CarouselContent, CarouselItem } from "./Carousel";
import Autoplay from "embla-carousel-autoplay";

interface Partner {
  id: number;
  name: string;
  tag: string;
}

interface PartnersCarouselProps {
  data: Partner[];
}

export const PartnersCarousel: React.FC<PartnersCarouselProps> = ({ data }) => {
  return (
    <Carousel
      opts={{
        loop: true,
        duration: 13000,
        dragFree: true,
      }}
      plugins={[
        Autoplay({
          delay: 0,
          stopOnInteraction: false,
        }),
      ]}
      className="w-full max-w-full"
    >
      <CarouselContent className="flex">
        {data.map((partner, index) => (
          <CarouselItem key={`${partner.id}-${index}`} className="">
            <img
              src={`/images/partners/${partner.tag}.svg`}
              alt={partner.name}
              className="w-full object-contain"
            />
          </CarouselItem>
        ))}
      </CarouselContent>
    </Carousel>
  );
};
