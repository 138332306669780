import React from "react";

interface CardItemType {
  text?: string;
  imagePath?: string;
  className?: string;
}

const Card: React.FC<CardItemType> = ({ text, className }) => {
  return (
    <div
      className={`flex p-4 sm:p-6 lg:p-8 gap-5 justify-start items-start w-full sm:w-[400px] lg:w-[450px] ${className}`}
    >
      <div className="bg-[#3BB085] w-[40px] sm:w-[45px] lg:w-[50px] aspect-square flex justify-center items-center rounded-lg backdrop-blur-md shadow-lg">
        {/* <Image
          alt="card image"
          src={`/icons/${imagePath}`}
          width={50}
          height={50}
          className="w-[80%]"
        ></Image> */}
      </div>

      <div className="text-[16px] sm:text-[18px] lg:text-[20px] text-[#fff] uppercase font-bold">
        {text}
        <div className="bg-[#3BB085] w-[80px] sm:w-[100px] lg:w-[120px] h-[2px] sm:h-[3px] rounded-sm mt-1"></div>
      </div>
    </div>
  );
};

export default Card;
