import nextIcon from "../../assets/icons/nextIcon.png";

interface CircularPaginationProps {
  activePage: number;
  setActivePage: (page: number) => void;
  totalPages: number;
}

export function CircularPagination({
  activePage,
  setActivePage,
  totalPages,
}: CircularPaginationProps) {
  const getItemProps = (index: number) => ({
    className: `rounded-full px-3 py-1 ${
      activePage === index ? "bg-gray-900 text-white" : "text-black"
    }`,
    onClick: () => setActivePage(index),
  });

  const next = () => {
    if (activePage < totalPages) {
      setActivePage(activePage + 1);
    }
  };

  const prev = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1);
    }
  };

  const renderPageNumbers = () => {
    const pages = [];

    // Always show the first page
    pages.push(
      <button key={1} {...getItemProps(1)}>
        1
      </button>
    );

    // Show ellipses if activePage is beyond the third page
    if (activePage > 3) {
      pages.push(<span key="start-ellipsis">...</span>);
    }

    // Show the pages around the activePage
    for (
      let i = Math.max(2, activePage - 1);
      i <= Math.min(totalPages - 1, activePage + 1);
      i++
    ) {
      if (i !== 1 && i !== totalPages) {
        pages.push(
          <button key={i} {...getItemProps(i)}>
            {i}
          </button>
        );
      }
    }

    // Show ellipses if activePage is not near the end
    if (activePage < totalPages - 2) {
      pages.push(<span key="end-ellipsis">...</span>);
    }

    // Always show the last page
    if (totalPages > 1) {
      pages.push(
        <button key={totalPages} {...getItemProps(totalPages)}>
          {totalPages}
        </button>
      );
    }

    return pages;
  };

  return (
    <div className="flex items-center gap-4 mt-10 mb-10">
      {totalPages > 1 && (
        <button
          className="flex items-center gap-2 rounded-full px-3 py-1"
          onClick={prev}
          disabled={activePage === 1}
        >
          <img
            src={nextIcon}
            style={{ transform: "rotate(180deg)" }}
            width={30}
            alt="Previous"
          />
        </button>
      )}

      {totalPages > 1 && (
        <div className="flex items-center gap-2">{renderPageNumbers()}</div>
      )}

      {totalPages > 1 && (
        <button
          className="flex items-center gap-2 rounded-full px-3 py-1"
          onClick={next}
          disabled={activePage === totalPages}
        >
          <img src={nextIcon} alt="Next" width={30} />
        </button>
      )}
    </div>
  );
}
