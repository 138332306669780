import { useEffect, useState, useRef } from "react";

const useCountUpOnScroll = (start: number, end: number, duration: number) => {
  const [count, setCount] = useState(start);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let observer: IntersectionObserver;
    let frame: number;
    const countUp = () => {
      const startTime = performance.now();
      const step = (timestamp: number) => {
        const progress = Math.min((timestamp - startTime) / duration, 1);
        setCount(Math.floor(progress * (end - start) + start));
        if (progress < 1) {
          frame = requestAnimationFrame(step);
        }
      };
      frame = requestAnimationFrame(step);
    };

    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      if (entries[0].isIntersecting) {
        countUp();
      }
    };

    if (ref.current) {
      observer = new IntersectionObserver(handleIntersect, { threshold: 0.1 });
      observer.observe(ref.current);
    }

    return () => {
      if (observer && ref.current) {
        observer.unobserve(ref.current);
      }
      if (frame) {
        cancelAnimationFrame(frame);
      }
    };
  }, [end, duration, start]);

  return [count, ref] as const;
};

export default useCountUpOnScroll;
