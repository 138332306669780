import React from "react";
import styles from "./RecentProjects.module.css";
import { Returntext } from "../../helpers/helpers";

interface RecentProjectProps {
  contentData?: any;
  lang?: string;
}

const RecentProjects: React.FC<RecentProjectProps> = ({
  contentData,
  lang,
}) => {
  return (
    <div className="bg-[#F8F8F8] flex flex-col justify-center items-center w-full px-4 sm:px-[5dvw] md:px-[8dvw] lg:px-[10dvw] py-[30px] sm:py-[50px] bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]">
      <div className="text-[#3BB085] uppercase font-semibold text-center">
        {Returntext(contentData, "our_work", lang)}
      </div>
      <div className="text-[#000] uppercase font-bold h2_font_ka text-[28px] sm:text-[42px] text-center">
        {Returntext(contentData, "our_work_txt", lang)}
      </div>
      {/* Removed gap for smaller screens */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-0 sm:gap-0 lg:gap-5 md:gap-7 mt-6 w-full">
        <a
          href={"/projects"}
          className={`col-span-1 lg:col-span-2 row-span-2 relative ${styles.container}`}
        >
          <img
            src="/images/project-1.jpg"
            alt="Project 1"
            className="object-cover w-full h-full"
          />
          <div
            className={`absolute bottom-0 w-full h-full ${styles.projects_glare}`}
          ></div>
          <div
            className={`absolute inset-0 flex items-center justify-center text-[#fff] p-5 text-center ${styles.project_info}`}
          >
            Multi-profile clinic on <br /> Chavchavadze Street
          </div>
        </a>

        <a
          href={"/projects"}
          className={`col-span-2 sm:col-span-2 lg:col-span-2 row-span-1 relative ${styles.container}`}
        >
          <img
            src="/images/project-2.webp"
            alt="Project 2"
            className="object-cover w-full h-full"
          />
          <div
            className={`absolute bottom-0 w-full h-full ${styles.projects_glare}`}
          ></div>
          <div
            className={`absolute inset-0 flex items-center justify-center text-[#fff] px-5 sm:px-[50px] text-center ${styles.project_info}`}
          >
            TBC Bank&apos;s new head office on the slope of Lisi Lake.
          </div>
        </a>

        <a
          href={"/projects"}
          className={`col-span-1 relative ${styles.container}`}
        >
          <img
            src="/images/project-3.jpg"
            alt="Project 3"
            className="object-cover w-full h-full"
          />
          <div
            className={`absolute bottom-0 w-full h-full ${styles.projects_glare}`}
          ></div>
          <div
            className={`absolute inset-0 flex items-center justify-center text-[#fff] p-2 text-center ${styles.project_info}`}
          >
            Hilton in Vejini, Kakheti
          </div>
        </a>

        <a
          href={"/projects"}
          className={`col-span-1 row-span-1 overflow-hidden relative ${styles.container}`}
        >
          <img
            src="/images/project-4.jpg"
            alt="Project 4"
            className="object-cover w-full h-full"
          />
          <div
            className={`absolute bottom-0 w-full h-full ${styles.projects_glare}`}
          ></div>
          <div
            className={`absolute inset-0 flex items-center justify-center text-[#fff] p-2 text-center ${styles.project_info}`}
          >
            Bank of Georgia Head Office • BMS system
          </div>
        </a>

        <a
          href={"/projects"}
          className={`col-span-2 sm:col-span-2 lg:col-span-2 row-span-1 relative ${styles.container}`}
        >
          <img
            src="/images/project-5.jpg"
            alt="Project 5"
            className="object-cover w-full h-full"
          />
          <div
            className={`absolute bottom-0 w-full h-full ${styles.projects_glare}`}
          ></div>
          <div
            className={`absolute inset-0 flex items-center justify-center text-[#fff] p-2 text-center ${styles.project_info}`}
          >
            Business Centre adjacent to Mziuri Park
          </div>
        </a>
      </div>
      <div className="w-full flex justify-center lg:justify-end">
        <a
          href={"/projects"}
          className="!text-[#000] mt-3 text-[16px] sm:text-[18px] underline cursor-pointer"
        >
          {Returntext(contentData, "our_work_see_more", lang)}
        </a>
      </div>
    </div>
  );
};

export default RecentProjects;
