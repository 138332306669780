interface ButtonItemType {
  text?: string;
  color?: "main" | "secondary" | string;
  action?: any;
  size?: string;
  className?: string;
}

const Button: React.FC<ButtonItemType> = ({ text, color, size, className }) => {
  let sizeProps = "px-[20px] py-[10px] text-[16px]";

  if (color === "main") {
    color = "#3BB085";
  } else if (color === "secondary") {
    color = "#616161";
  } else if (!color) {
    color = "#3BB085";
  }

  if (!text) {
    text = "";
  }

  if (size === "small") {
    sizeProps = "px-[12px] py-[6px] text-[12px]";
  } else if (size === "big") {
    sizeProps = "px-[34px] py-[12px] text-[22px]";
  }
  return (
    <div
      className={`w-fit ${sizeProps} bg-[${color}] font-sans font-semibold text-[#fff] rounded-md cursor-pointer !z-30 relative  ${className} `}
    >
      {text}
    </div>
  );
};

export default Button;
