import { Returntext } from "../../helpers/helpers";
import Button from "../button/Button";

interface ContactUsProps {
  contentData?: any;
  lang?: string;
}

const ContactUs: React.FC<ContactUsProps> = ({ contentData, lang }) => {
  return (
    <div className="flex flex-col md:flex-row bg-[#3BB085] justify-between items-center w-full py-5 px-6 md:px-[10vw] lg:px-[17vw]">
      <div className="text-[18px] md:text-[22px] text-white uppercase font-bold mb-4 md:mb-0">
        {Returntext(contentData, "any_questions", lang)}
      </div>

      <a href={`/contact`}>
        <Button
          text={Returntext(contentData, "BTN_CONTACT_US", lang)}
          className="bg-white !text-[#3BB085] px-6 md:px-10 text-[16px] md:text-[18px]"
          size={"normal"}
        />
      </a>
    </div>
  );
};

export default ContactUs;
