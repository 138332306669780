import { useEffect, useRef } from "react";

export const Maps = () => {
  const map1Ref = useRef<HTMLIFrameElement>(null);
  const map2Ref = useRef<HTMLIFrameElement>(null);

  useEffect(() => {
    const lazyLoadMap = (
      entries: IntersectionObserverEntry[],
      observer: IntersectionObserver
    ) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const iframe = entry.target as HTMLIFrameElement;
          iframe.src = iframe.dataset.src || "";
          observer.unobserve(iframe);
        }
      });
    };

    const observer = new IntersectionObserver(lazyLoadMap, {
      rootMargin: "200px",
    });

    if (map1Ref.current) observer.observe(map1Ref.current);
    if (map2Ref.current) observer.observe(map2Ref.current);

    return () => observer.disconnect();
  }, []);

  return (
    <div className="flex gap-5 flex-wrap lg:flex-nowrap">
      <div className="w-full lg:w-[400px]">
        <iframe
          data-src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2974.8153533199948!2d44.77145427648046!3d41.78919287097539!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40446ddc16c6f5ab%3A0xffe01f84a12aec3d!2sMontage%20Georgia!5e0!3m2!1sen!2sge!4v1722419830183!5m2!1sen!2sge"
          width="100%"
          height="350"
          title="1"
          ref={map1Ref}
          allowFullScreen
          loading="lazy"
          className="rounded-sm"
        ></iframe>
        <div className="text-[14px] opacity-55 text-semibold mt-2">
          Main Office - #19, George Papashvili str, 0159. Tbilisi, Georgia
        </div>
      </div>

      <div className="w-full lg:w-[400px]">
        <iframe
          data-src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2973.5293084498567!2d44.78752187648146!3d41.81689916924302!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40446fb75338823f%3A0x94312f2d04fb7c0b!2sMontage%20Georgia%20Werehouse!5e0!3m2!1sen!2sge!4v1722421058349!5m2!1sen!2sge"
          width="100%"
          height="350"
          title="2"
          ref={map2Ref}
          allowFullScreen
          loading="lazy"
          className="rounded-sm"
        ></iframe>
        <div className="text-[14px] opacity-55 text-semibold mt-2">
          Warehouse - Aleksandre Managadze St, Tbilisi, Georgia
        </div>
      </div>
    </div>
  );
};
