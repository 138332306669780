import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { searchProduct } from "../helpers/fetcher"; // Adjusted imports as needed
import { TextGenerateEffect } from "../components/textGenerateEffect/TextGenerateEffect";
import {
  CardBody,
  CardContainer,
  CardItem,
} from "../components/animatedCard/AnimatedCard";
import { CircularPagination } from "../components/circularPagination/circularPagination";
import { Returntext, useContent, useLanguages } from "../helpers/contLang";
import notFoundIcon from "../assets/images/noItemFound.jpg";
import imgOther from "../assets/images/otherImage.png";

export default function ProductsSearch() {
  const { name } = useParams<any>();
  const [currentPage, setCurrentPage] = useState(1);
  const [productData, setProductData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [showNotFound, setShowNotFound] = useState(false);
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  // const [productImages, setProductImages] = useState<{ [key: string]: string }>(
  //   {}
  // );

  // const getProductImagePath = async (
  //   code: string | undefined
  // ): Promise<string> => {
  //   if (!code) return imgOther;

  //   const formattedCodeHyphen = code
  //     .trim()
  //     .replace(/\s+/g, "")
  //     .replace(/AB(?=-)/, "ABB");

  //   const formattedCodeUnderscore = formattedCodeHyphen.replace(/-/g, "_");

  //   const imagePaths = [
  //     `https://montage.ge/images/prod/${formattedCodeHyphen}.jpg`,
  //     `https://montage.ge/images/prod/${formattedCodeHyphen}.png`,
  //     `https://montage.ge/images/prod/${formattedCodeUnderscore}.jpg`,
  //     `https://montage.ge/images/prod/${formattedCodeUnderscore}.png`,
  //   ];

  //   const checkImage = (path: string): Promise<boolean> => {
  //     return new Promise((resolve) => {
  //       const img = new Image();
  //       img.src = path;
  //       img.onload = () => resolve(true);
  //       img.onerror = () => resolve(false);
  //     });
  //   };

  //   for (const path of imagePaths) {
  //     const isValid = await checkImage(path);
  //     if (isValid) {
  //       return path;
  //     }
  //   }

  //   return imgOther;
  // };

  const fetchData = useCallback(
    async (page: number) => {
      try {
        setLoading(true);
        setShowNotFound(false);

        const products = await searchProduct(page, 12, name!);
        console.log(products);
        setProductData(products);

        if (products?.items?.length === 0) {
          setTimeout(() => {
            setShowNotFound(true);
          }, 1500);
        }

        // Load images for each product asynchronously
        // const imagePromises = products?.items?.map(async (product: any) => {
        //   const imagePath = await getProductImagePath(product?.code);
        //   return { code: product?.code, imagePath };
        // });

        // const loadedImages = await Promise.all(imagePromises);

        // const imageMap = loadedImages.reduce((acc: any, curr: any) => {
        //   acc[curr.code] = curr.imagePath;
        //   return acc;
        // }, {});

        // setProductImages(imageMap);

        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setLoading(false);
        setTimeout(() => {
          setShowNotFound(true);
        }, 1500);
      }
    },
    [name]
  );

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage, fetchData]);

  return (
    <div className="min-h-screen bg-white bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]">
      <div className="pt-[200px] w-[90vw] lg:w-[80vw] m-auto">
        <TextGenerateEffect
          words={lang === "ka" ? "პროდუქტები" : "Products"}
          className="mb-10 text-2xl lg:text-4xl"
        />
        {loading ? (
          <div className="flex justify-center items-center h-1/2">
            <span className="loading loading-ring loading-xl w-[30%] bg-[#39B085]"></span>
          </div>
        ) : productData?.items?.length > 0 ? (
          <div className="flex flex-wrap justify-between items-center gap-3 w-full">
            {productData.items.map((product: any, key: any) => (
              <CardContainer className="inter-var" key={key}>
                <CardBody className="max_width bg-white relative group/card hover:shadow-xl hover:shadow-emerald-500/[0.1] border-gray-200 h-auto rounded-xl p-4 border flex flex-col justify-between">
                  <div>
                    <CardItem
                      translateZ="50"
                      className="text-sm lg:text-l font-bold text-neutral-600"
                    >
                      {lang === "ka" ? product?.name_ka : product?.name_en}
                    </CardItem>
                    <CardItem
                      as="p"
                      translateZ="60"
                      className="text-neutral-500 text-xs lg:text-sm max-w-sm mt-2"
                    >
                      {lang === "ka"
                        ? product?.description_ka
                        : product?.description_en}
                    </CardItem>
                    <CardItem
                      as="p"
                      translateZ="60"
                      className="text-neutral-500 text-xs lg:text-sm max-w-sm mt-2"
                    >
                      {product?.code?.split(/-(.*)/)[1]?.trim()}
                    </CardItem>
                    <CardItem
                      translateZ="100"
                      className="w-full mt-4 flex justify-center"
                    >
                      <img
                        src={`https://montage.ge/img/${product?.photo}`}
                        alt={product?.name_en}
                        className="w-auto h-[150px] lg:h-[200px] object-cover"
                        onError={(e) => {
                          e.currentTarget.src = imgOther;
                        }}
                      />
                    </CardItem>
                  </div>
                  <CardItem
                    translateZ={20}
                    onClick={() => {
                      const url = `https://montage.ge/img/${product?.pdf}`;
                      window.open(url, "_blank", "noopener noreferrer");
                    }}
                    className="px-4 py-2 rounded-xl text-xs font-normal text-neutral-600 cursor-pointer"
                  >
                    {Returntext(contentManagementData, "data_sheet", lang)}
                  </CardItem>
                </CardBody>
              </CardContainer>
            ))}
          </div>
        ) : (
          showNotFound && (
            <div className="flex justify-center items-center mt-10">
              <img
                src={notFoundIcon}
                alt="card"
                width={1440}
                height={740}
                className="w-full lg:w-[60%] object-cover"
              />
            </div>
          )
        )}
        <div className="flex justify-center items-center mt-4">
          {productData?.meta?.totalPages > 1 && (
            <CircularPagination
              activePage={currentPage}
              setActivePage={setCurrentPage}
              totalPages={productData?.meta?.totalPages}
            />
          )}
        </div>
      </div>
    </div>
  );
}
