import React from "react";
import { Link } from "react-router-dom";
import styles from "./ProductCategories.module.css";
import { Returntext } from "../../helpers/helpers";

interface Category {
  title: string;
  title_ka: string;
  imgHref: string;
  href: string;
}

interface AboutUSProps {
  contentData?: any;
  lang?: string;
}

const ProductCategories: React.FC<AboutUSProps> = ({ contentData, lang }) => {
  const categories: Category[] = [
    {
      title: "Transformators",
      title_ka: "ტრანსფორმატორები",
      imgHref: `https://montage.ge/img/img1.JPG`,
      href: "/products/transformators/1300",
    },
    {
      title: "Generators",
      title_ka: "გენერატორები",
      imgHref: `https://montage.ge/img/img2.JPG`,
      href: "/products/generators/1400",
    },
    {
      title: "Cables",
      title_ka: "სადენები",
      imgHref: `https://montage.ge/img/img3.JPG`,
      href: "/products/cables/2300",
    },
    {
      title: "Security Systems",
      title_ka: "დაცვის სისტემები",
      imgHref: `https://montage.ge/img/img4.jpeg`,
      href: "/products/security-systems/3600",
    },
    {
      title: "Lightning Fixtures",
      title_ka: "სანათები",
      imgHref: `https://montage.ge/img/img5.png`,
      href: "/products/lightning-fixtures/2700",
    },
    {
      title: "Sockets and Switches",
      title_ka: "როზეტები და ჩამრთველები",
      imgHref: `https://montage.ge/img/img6.JPG`,
      href: "/products/sockets-and-switches/2600",
    },
  ];

  return (
    <div className="flex justify-center flex-col items-center gap-4 w-full max-w-7xl mx-auto px-2 md:px-6 py-8 md:py-12 bg-white bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]">
      <div className="text-[#3BB085] uppercase font-semibold text-base md:text-lg text-center">
        {Returntext(contentData, "our_products", lang)}
      </div>
      <div className="text-[#000] uppercase font-bold text-xl md:text-[42px] h2_font_ka text-center">
        {Returntext(contentData, "our_products_popular", lang)}
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-6 p-4 md:p-6 w-full justify-items-center">
        {categories.map((category, index) => (
          <Link to={category.href} key={index}>
            <div
              className={`relative bg-white rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition-transform duration-300 w-[180px] h-[180px] sm:w-[200px] sm:h-[200px] md:w-[240px] md:h-[240px] ${styles.container}`}
            >
              <img
                src={category.imgHref}
                alt={lang === "en" ? category.title : category.title_ka}
                className="w-full h-full object-cover"
              />
              <div
                className={`absolute bottom-0 w-full h-full ${styles.productCategories_glare}`}
              ></div>
              <div
                className={`absolute inset-0 flex items-center justify-center text-[#fff] p-5 h2_font_ka text-base sm:text-lg md:text-[30px] ${styles.productCategories_info}`}
              >
                {lang === "en" ? category.title : category.title_ka}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ProductCategories;
