import { useEffect, useState } from "react";
import { fetchProjects } from "../helpers/fetcher";
import { TextGenerateEffect } from "../components/textGenerateEffect/TextGenerateEffect";
import { Returntext, useContent, useLanguages } from "../helpers/contLang";
import { ExpandableCards } from "../components/expandableCards/ExpandableCards";

interface Project {
  id: number;
  title_ge: string;
  title_en: string;
  desc_ge: string;
  desc_en: string;
  full_ge: string;
  full_en: string;
  fname: string;
  prDate: string;
  cat_name: string;
}

function Projects() {
  const [projectsData, setProjectsData] = useState<Project[]>([]);
  const [showFinished, setShowFinished] = useState<boolean>(false);

  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  const fetchData = async () => {
    const projects: any = await fetchProjects();
    setProjectsData(projects);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Function to filter projects based on whether prDate exists (finished or not)
  const filteredProjects = showFinished
    ? projectsData.filter(
        (project) => project.cat_name === "Completed Projects"
      )
    : projectsData.filter((project) => project.cat_name === "Current Projects");

  return (
    <div className="bg-white bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px] mb-9">
      <div className="pt-[100px] lg:pt-[200px] w-[90vw] lg:w-[80vw] m-auto">
        <TextGenerateEffect
          words={Returntext(contentManagementData, "our_work", lang)}
          className="text-3xl lg:text-5xl font-bold"
        />

        {/* Prettier Switcher for Current / Finished Projects */}
        <div className="flex justify-center my-6">
          <button
            className={`px-4 lg:px-6 py-2 lg:py-3 text-md lg:text-lg font-medium rounded-l-full transition-all duration-300 ease-in-out ${
              !showFinished
                ? "bg-[#3BB085] text-white shadow-lg transform scale-105"
                : "bg-gray-200 text-gray-600 hover:bg-gray-300"
            }`}
            onClick={() => setShowFinished(false)}
          >
            {lang === "en" ? "CURRENT" : "მიმდინარე"}
          </button>
          <button
            className={`px-4 lg:px-6 py-2 lg:py-3 text-md lg:text-lg font-medium rounded-r-full transition-all duration-300 ease-in-out ${
              showFinished
                ? "bg-[#3BB085] text-white shadow-lg transform scale-105"
                : "bg-gray-200 text-gray-600 hover:bg-gray-300"
            }`}
            onClick={() => setShowFinished(true)}
          >
            {lang === "en" ? "FINISHED" : "დასრულებული"}
          </button>
        </div>

        <ExpandableCards data={filteredProjects} />
      </div>
    </div>
  );
}

export default Projects;
