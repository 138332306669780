import { Outlet } from "react-router-dom";
import "./index.css";
import TopScrollBar from "./components/navbar/TopScrollBar";
import TopInfoCont from "./components/navbar/TopInfoCont";
import { Navbar } from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import { useContent, useLanguages } from "./helpers/contLang";
import { useEffect, useState } from "react";
import { fetchNavbarData } from "./helpers/fetcher";
import { NavbarItemType } from "./helpers/types";

export default function RootLayout() {
  const [navbarData, setNavbarData] = useState<NavbarItemType[]>([]);
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();
  const fetchData = async () => {
    const data = await fetchNavbarData();
    setNavbarData(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <TopScrollBar />
      <div className="absolute flex flex-col justify-center items-center w-full">
        <TopInfoCont contentData={contentManagementData} lang={lang} />
        <Navbar
          lang={lang}
          navbarData={navbarData}
          contentData={contentManagementData}
        />
      </div>
      <Outlet />
      <Footer lang={lang} contentData={contentManagementData} />
    </>
  );
}
