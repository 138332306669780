import AboutUS from "../components/aboutUS/AboutUS";
import CardSection from "../components/card/CardSection";
import ContactUs from "../components/contactUs/ContactUs";
import Hero from "../components/hero/Hero";
import Partners from "../components/partners/partners";
import ProductCategories from "../components/productCategories/ProductCategories";
import RecentProjects from "../components/recentProjects/RecentProjects";
import StatsSection from "../components/statsSection/StatsSection";
import { useContent, useLanguages } from "../helpers/contLang";

const Home: React.FC = () => {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <div>
      <Hero contentData={contentManagementData} lang={lang} />
      <ProductCategories contentData={contentManagementData} lang={lang} />
      <AboutUS contentData={contentManagementData} lang={lang} />
      <RecentProjects contentData={contentManagementData} lang={lang} />
      <CardSection contentData={contentManagementData} lang={lang} />
      <Partners contentData={contentManagementData} lang={lang} />
      <StatsSection contentData={contentManagementData} lang={lang} />
      <ContactUs contentData={contentManagementData} lang={lang} />
    </div>
  );
};

export default Home;
