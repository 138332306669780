export const Returntext = (
  data: any,
  identifier: string,
  lang?: string
): string => {
  if (!data || !identifier || !lang)
    return ``;

  const item = data.find((item: any) => item.identifier === identifier);

  if (!item)
    return "";
  const text = item[`name${lang.charAt(0).toUpperCase() + lang.slice(1)}`];

  return text || "";
};

export const staticGlobal = {
  assets: {} as Record<string, { default?: string }>, // Explicit typing
};

export const getAssetUrl = (path: string): string => {
  if (!path) return "";
  if (path.startsWith("/src")) {
    const asset = staticGlobal.assets[path];
    return asset?.default || ""; // More robust nullish handling
  }
  return path; // Return original path for external URLs or paths outside /src
};

