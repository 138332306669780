import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API_BASE_URL } from "./fetcher";

// Hook to fetch content management data
export const useContent = () => {
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: ["content-management"],
    queryFn: () => {
      return axios.get(`${API_BASE_URL}/content`);
    },
    staleTime: Infinity,
  });

  const contentManagementData = data ? data.data : [];

  return {
    contentManagementData,
    contentIsLoading: isLoading,
    contentIsError: error,
    refetchContent: refetch,
  };
};

// Hook to manage the language setting
export const useLanguages = () => {
  const initialLanguage = localStorage.getItem("language") || "ka";

  const { data, refetch } = useQuery({
    queryKey: ["lang"],
    queryFn: () => {
      return initialLanguage;
    },
    staleTime: Infinity,
  });

  const lang = data;

  return {
    lang,
    refetchLang: refetch, // Return refetch function if needed
  };
};

const Returntext = (data: any, identifier: string, lang: string = 'ka') => {
  const filteredData = data.filter((item:any) => item.identifier === identifier);

  if (filteredData.length > 0) {
    const text =
      filteredData[0][`name${lang.charAt(0).toUpperCase() + lang.slice(1)}`];
    return (
      text ||
      `Text not found for identifier '${identifier}' and language '${lang}'`
    );
  } else {
    return "";
  }
};

export { Returntext };
