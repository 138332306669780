import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Layout from "./Layout";
import Home from "./views/Home";
import Contact from "./views/Contact";
import Products from "./views/Products";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./index.css";
import Services from "./views/Services";
import Projects from "./views/Projects";
import ProductsSearch from "./views/ProductsSearch";
// import Services from "./views/Services";

function App() {
  const queryClient = new QueryClient();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Routes>
            <Route element={<Layout />}>
              <Route path="/" element={<Home />} />
              <Route
                path="/products/:categoryName/:categoryId"
                element={<Products />}
              />
              <Route
                path="/productsSearch/:name"
                element={<ProductsSearch />}
              />
              <Route path="/search/:name" element={<ProductsSearch />} />
              <Route path="/services" element={<Services />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/contact" element={<Contact />} />

              <Route path="/*" element={<Navigate to="/" />} />
            </Route>
          </Routes>
        </Router>
      </QueryClientProvider>
    </>
  );
}

export default App;
