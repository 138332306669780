import React from "react";
import LanguageBtn from "./LocalSwitcher";
import { Returntext } from "../../helpers/helpers";

interface TopInfoContProps {
  lang?: string;
  contentData?: string;
}
interface TopInfoItem {
  title: string;
  value: string;
}

const TopInfoCont: React.FC<TopInfoContProps> = ({ contentData, lang }) => {
  const topInfoData: TopInfoItem[] = [
    {
      title: Returntext(contentData, "email_name", lang),
      value: Returntext(contentData, "email_value", lang),
    },
    {
      title: Returntext(contentData, "Office_name", lang),
      value: Returntext(contentData, "Office_value", lang),
    },
    {
      title:Returntext(contentData, "Sales_name", lang),
      value: Returntext(contentData, "Sales_value", lang),
    },
    {
      title: Returntext(contentData, "wh_name", lang),
      value: Returntext(contentData, "wh_value", lang),
    },
  ];

  const renderItems = (items: TopInfoItem[]): JSX.Element[] => {
    return items.map((item, index) => (
      <div key={index}>
        <div className="text-[12px] font-medium text-[#fff] h1_font_ka">
          {item.title}
        </div>
        <div className="text-[12px] font-normal text-[#999999] mt-1 nav_font_ka">
          {item.value}
        </div>
      </div>
    ));
  };

  return (
    <div className="sm:flex hidden  justify-between items-center py-4 bg-[#2B2D37] px-[10%] w-full relative z-40 ">
      <div className=" gap-5 flex">{renderItems(topInfoData)}</div>

      <LanguageBtn />
    </div>
  );
};

export default TopInfoCont;
