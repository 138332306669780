import { Returntext } from "../../helpers/helpers";
import useCountUpOnScroll from "../../hooks/useCountUpOnScroll";

interface StatsSectionType {
  className?: string;
  contentData?: any;
  lang?: string;
}

const StatsSection: React.FC<StatsSectionType> = ({ contentData, lang }) => {
  const [yearsOfExperience, ref1] = useCountUpOnScroll(0, 15, 2000);
  const [projectsCompleted, ref2] = useCountUpOnScroll(0, 392, 2000);
  const [partnersWorldwide, ref3] = useCountUpOnScroll(0, 33, 2000);
  const [productsInStore, ref4] = useCountUpOnScroll(0, 25800, 2000);

  return (
    <div className="flex flex-col justify-center items-center w-full gap-2 h-fit relative md:h-[232px]">
      <div className="w-full h-full bg-[#000] absolute opacity-80"></div>
      <img
        src="/images/stats.jpg"
        alt="Project 2"
        width={1440}
        height={232}
        className="object-cover w-full h-full"
      />
      <div className="absolute md:flex gap-5 grid grid-cols-2">
        <div
          ref={ref1}
          className="flex flex-col justify-center items-center bg-white bg-opacity-10 backdrop-blur-md md:p-4 p-2 rounded-lg shadow-lg gap-2 md:w-[220px] w-[120px] h-fit md:h-auto text-[#fff]"
        >
          <div className="md:text-[40px] text-[28px] h2_font_ka">
            {yearsOfExperience}
          </div>
          <div className="uppercase md:text-[20px] text-[16px] h2_font_ka">
            {Returntext(contentData, "experience", lang)}
          </div>
        </div>
        <div
          ref={ref2}
          className="flex flex-col justify-center items-center bg-white bg-opacity-10 backdrop-blur-md md:p-4 p-2 rounded-lg shadow-lg gap-2 md:w-[220px] w-[120px] h-fit md:h-auto text-[#fff]"
        >
          <div className="md:text-[40px] text-[28px] h2_font_ka">
            {projectsCompleted}
          </div>
          <div className="uppercase md:text-[20px] text-[16px] h2_font_ka">
            {Returntext(contentData, "projects", lang)}
          </div>
        </div>
        <div
          ref={ref3}
          className="flex flex-col justify-center items-center bg-white bg-opacity-10 backdrop-blur-md md:p-4 p-2 rounded-lg shadow-lg gap-2 md:w-[220px] w-[120px] h-fit md:h-auto text-[#fff]"
        >
          <div className="md:text-[40px] text-[28px] h2_font_ka">
            {partnersWorldwide}
          </div>
          <div className="uppercase md:text-[20px] text-[16px] h2_font_ka">
            {Returntext(contentData, "partner", lang)}
          </div>
        </div>
        <div
          ref={ref4}
          className="flex flex-col justify-center items-center bg-white bg-opacity-10 backdrop-blur-md md:p-4 p-2 rounded-lg shadow-lg gap-2 md:w-[220px] w-[120px] h-fit md:h-auto text-[#fff]"
        >
          <div className="md:text-[40px] text-[28px] h2_font_ka">
            {productsInStore}
          </div>
          <div className="uppercase md:text-[20px] text-[16px] h2_font_ka">
            {Returntext(contentData, "product", lang)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StatsSection;
