import { Maps } from "../components/maps/Maps";
import { useContent, useLanguages } from "../helpers/contLang";
import { Returntext } from "../helpers/helpers";

export default function Contact() {
  const { lang } = useLanguages();
  const { contentManagementData } = useContent();

  return (
    <div className="pt-[100px] lg:pt-[200px] px-[5vw] lg:px-[10vw] min-h-[90vh] bg-white bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:24px_24px]">
      <h1 className="text-[32px] lg:text-[52px] text-[#2B2D37] font-bold text-center">
        {Returntext(contentManagementData, "location", lang)}
      </h1>
      <div className="flex flex-col lg:flex-row justify-center items-center gap-5 mt-11 h-full">
        <Maps />
      </div>
    </div>
  );
}
