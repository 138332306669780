import React, { forwardRef } from "react";
import { useNavigate } from "react-router-dom";

interface NavbarItemType {
  name: string;
  href: string;
  isMainItem: boolean;
}

const NavbarItem = forwardRef<HTMLAnchorElement, NavbarItemType>(
  ({ name, href, isMainItem }, ref) => {
    const navigate = useNavigate();
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      
      if (href === "/partners") {
        e.preventDefault();
        if (window.location.pathname === "/") {
          const section = document.getElementById("partners-section");
          if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        } else {
          navigate("/");
          setTimeout(() => {
            const section = document.getElementById("partners-section");
            if (section) {
              section.scrollIntoView({ behavior: "smooth", block: "start" });
            }
          }, 100); // Adjust the timeout as needed
        }
      }
      if (href === "/about-us") {
        e.preventDefault();
        if (window.location.pathname === "/") {
          const section = document.getElementById("aboutUS");
          if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "start" });
          }
        } else {
          navigate("/");
          setTimeout(() => {
            const section = document.getElementById("aboutUS");
            if (section) {
              section.scrollIntoView({ behavior: "smooth", block: "start" });
            }
          }, 100); // Adjust the timeout as needed
        }
      }
    };

    return (
      <li className="text-[14px]">
        <a
          href={href}
          ref={ref}
          className={
            isMainItem
              ? "text-white nav_font_ka "
              : "text-gray-500 nav_font_ka "
          }
          onClick={handleClick}
        >
          {name}
        </a>
      </li>
    );
  }
);

NavbarItem.displayName = "NavbarItem";

export default NavbarItem;
