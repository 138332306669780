import { useEffect, useState } from "react";
import { useLanguages } from "../../helpers/contLang";
import ka from "../../assets/icons/ka_flag.svg";
import en from "../../assets/icons/en_flag.svg";

const LanguageBtn = () => {
  const initialLanguage = localStorage.getItem("language") || "ka";
  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage);
  const { refetchLang } = useLanguages();

  const changeLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "ka" : "en";
    setCurrentLanguage(newLanguage);
    localStorage.setItem("language", newLanguage);
  };

  useEffect(() => {
    refetchLang();
    // eslint-disable-next-line
  }, [currentLanguage]);

  return (
    <div onClick={changeLanguage}>
      <button style={{ backgroundColor: "transparent", border: "none" }}>
        <img
          className="lang-icon"
          alt=""
          style={{ width: "25px" }}
          src={currentLanguage === "en" ? ka : en}
        />
      </button>
    </div>
  );
};

export default LanguageBtn;
